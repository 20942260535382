import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/cbq.css';

loadCSS('cbq-site');

export const CbqSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default CbqSiteWrapper;
